export interface OpeningHours {
  slots: Array<{
    from: string;
    to: string;
  }>;
  weekday: number;
}
export interface ClosingDay {
  date: string;
  note?: string;
}
export interface AppLocation {
  businessName: string;
  logoUrl?: string;
  address: string;
  city: string;
  province: string;
  zipCode: string;
  geo?: { lat: number; lon: number };
  email?: string;
  phone?: string;
  facebook?: string;
  instagram?: string;
  whatsapp?: string;
  description?: string;
  banner?: {
    image: string;
    link: string;
  };
  openingHours: Array<OpeningHours>;
  closingDays: Array<ClosingDay>;
}

export function fullAddress(l: AppLocation) {
  return `${l.address}, ${l.zipCode}, ${l.city} (${l.province})`;
}

export function shortAddress(l: AppLocation) {
  return `${l.address}, ${l.city}`;
}
