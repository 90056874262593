<template lang="pug">
div.dialog__backdrop.d-flex.flex-column.justify-end.overflow-hidden(@click="onClose" v-if="localValue" ref="backdrop")
    slot(name="default")
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"])

const localValue = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emit("update:modelValue", value);
  }
});

const backdrop = ref();
const onClose = (event: any) => {
  if (event.target === backdrop.value) {
    localValue.value = false;
  }
};
</script>

<style lang="scss">
.dialog__backdrop {
  height: var(--app-height);
  min-height: var(--app-height);
  max-height: var(--app-height);
  transform-origin: bottom center;
  position: relative;
  z-index: -1;
}
</style>
