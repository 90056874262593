<template lang="pug">
Teleport(:to="teleportTarget")
  transition(:name="transition ? transition : 'grow'" @before-enter="onBeforeEnter")
    EatDialog(:key="key" v-model="showDialog")
      div.absolute-blr.h-100.max-h-100.rounded-t-xxl.bg-white.d-flex.flex-column.flex-grow-1.elevation-5(v-scrollable="false")
        header.flex-grow-0.d-flex(ref="header")
          slot(name="header")
        main.flex-grow-1.text-black.d-flex.flex-column(id="modalDescription" v-scrollable="scrollable")
          slot(name="main")
        footer.flex-grow-0(ref="footer")
          slot(name="footer")
        div.floating-btn.pa-2
          slot(name="floating-btn")
</template>

<script setup lang="ts">
import { ref, watch, type PropType } from "vue";
import EatDialog from "@/components/modals/EatDialog.vue";
import type { Teleport } from "vue";
import { computed } from "vue";

export interface Point {
  x: number;
  y: number;
}

const props = defineProps({
  teleportTarget: {
    type: String,
    default: "#dialog"
  },
  modelValue: {
    type: Boolean,
    required: true
  },
  transition: {
    type: String,
    required: false
  },
  origin: {
    type: Object as PropType<Point>,
    required: false
  },
  scrollable: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(["update:modelValue"]);

const showDialog = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit("update:modelValue", value)
});

const key = ref(0);
watch(showDialog, () => key.value += 1);

const onBeforeEnter = (el: HTMLElement) => {
  if (!props.origin) {
    el.style.transformOrigin = "bottom center";
  } else {
    el.style.transformOrigin = `${props.origin.x}px ${props.origin.y}px`;
  }
};
</script>

<style lang="scss" scoped>
.floating-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 38px;
}


.grow-from-point-enter-active,
.grow-from-point-leave-active {
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.grow-from-point-enter,
.grow-from-point-leave-to {
  transform: scale(0);
}

.grow-enter-active,
.grow-leave-active,
.shrink-on-leave-leave-active {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.grow-enter,
.grow-leave-to,
.shrink-on-leave-leave-to {
  transform: translateY(100%);
}
</style>
