<template lang="pug">
div.chip-container.rounded-pill.border.pa-1.d-flex.align-center
    div.px-3 {{ allergen.name }} 
    div.icon_container.overflow-hidden.rounded-circle(:style="`background-color: ${getColor(allergen.code)}`")
        img.icon(:src="allergen.imageUrl")
</template>

<script setup lang="ts">
import { type Allergen, AllergenType } from '@/models/Product';
import type { PropType } from 'vue';

defineProps({
    allergen: {
        type: Object as PropType<Allergen>,
        required: true
    },
});



const getColor = (code: AllergenType) => {
    const map = {
        [AllergenType.MILK]: "#D4EBF9",
        [AllergenType.GLUTEN]: "#FFE7AB",
        [AllergenType.CRUSTACEANS]: "#F5A9A9",
        [AllergenType.EGGS]: "#FFCC87",
        [AllergenType.PEANUTS]: "#FFCC87",
        [AllergenType.SOYA]: "#D7E39B",
        [AllergenType.MUSTARD]: "#FFE7AB",
        [AllergenType.FISH]: "#D4EBF9",
        [AllergenType.CELERY]: "#F5A9A9",
        [AllergenType.LUPIN]: "#FFCC87",
        [AllergenType.MOLLUSCS]: "#D7E39B",
        [AllergenType.NUTS]: "#FFE7AB",
        [AllergenType.SESAME]: "#D4EBF9",
        [AllergenType.SULPHITES]: "#D4EBF9",
        [AllergenType.DEFAULT]: "#D4EBF9",
    };
    return map[code] || map[AllergenType.DEFAULT];
}
</script>

<style lang="scss">
.chip-container {

    .icon_container {
        --size: 24px;
        width: var(--size);
        height: var(--size);

        .icon {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
</style>