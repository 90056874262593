import type { OpeningHours } from "@/models/AppLocation";
import { onMounted, ref } from "vue";
import { DateTime } from "luxon";

export default function useOpeningHours(rawOpeningHours: OpeningHours[] = []) {
  const openingHours = ref<string[]>([]);
  const todaysOpeningHours = ref("");
  const isLocationOpenToday = ref(false);

  const formatOpeningHours = (rawOpeningHours: OpeningHours) => {
    let formattedOpeningHours = "";
    if (rawOpeningHours && rawOpeningHours.slots && rawOpeningHours.slots.length) {
      rawOpeningHours.slots.forEach((slot, i) => {
        formattedOpeningHours += `${slot.from} - ${slot.to} ${
          rawOpeningHours.slots.length > 1 && i !== rawOpeningHours.slots.length - 1 ? " | " : ""
        }`;
      });
    }
    return formattedOpeningHours;
  };

  onMounted(() => {
    const todaysWeekDay = DateTime.now().weekday % 7;
    const rawTodaysOpeningHours = rawOpeningHours.find(day => day.weekday === todaysWeekDay);

    if (!rawOpeningHours?.length) isLocationOpenToday.value = false;
    else {
      if (rawTodaysOpeningHours?.slots?.length) {
        isLocationOpenToday.value = true;
        todaysOpeningHours.value = formatOpeningHours(rawTodaysOpeningHours);
      }
      openingHours.value = rawOpeningHours.map((day, i) => formatOpeningHours(day));
      //Sposto la domenica dal primo posto nell'array all'ultimo per allinearsi alle label che vanno da lunedì a domenica
      openingHours.value[openingHours.value.length] = openingHours.value[0];
      openingHours.value.splice(0, 1);
    }
  });

  return {
    openingHours,
    todaysOpeningHours,
    isLocationOpenToday
  };
}
